import { useCachedCreditPricingTable } from './useCreditPricingTable'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getCreditBalance } from '@/service/pay.service'
import { CreationModeEnum, CreditSpendProduct, CreditTopupProduct, ModelVersion } from '@/types'
import { CREATE_MODE_ALIAS, TEMPLATE_CREDIT_PRICE } from '@/constants'
import useIsLogin from './useIsLogin'
import { usePathname } from 'next/navigation'

interface CreditModal {
  creditBalance?: number
  availableCredits: number
  expirableCredits: number
  permentCredits: number
  availablePermentCredits: number
  availableExpirableCredits: number
  productsList: CreditTopupProduct[]
  spendProductsList: CreditSpendProduct[]
  refreshCredit?: Function
}

const CreditContext = createContext<CreditModal>({
  creditBalance: undefined,
  availableCredits: 0,
  expirableCredits: 0,
  permentCredits: 0,
  availablePermentCredits: 0,
  availableExpirableCredits: 0,
  productsList: [],
  spendProductsList: [],
})

export interface GetCreditPriceParams {
  mode: CreationModeEnum | string
  template_id?: string
  duration: number
  resolution: number | null
  version: ModelVersion
}

export const CreditProvider = CreditContext.Provider

export const useCreditValue = () => {
  const { data: pricingTable } = useCachedCreditPricingTable()
  const [availableCredits, setAvailableCredits] = useState(0)
  const [expirableCredits, setExpirableCredits] = useState(0)
  const [permentCredits, setPermentCredits] = useState(0)
  const [pendingSettlementCredits, setPendingSettlementCredits] = useState(0)

  const availableExpirableCredits = useMemo(() => {
    return Math.max(0, expirableCredits - pendingSettlementCredits)
  }, [pendingSettlementCredits, expirableCredits])

  const availablePermentCredits = useMemo(() => {
    return availableCredits - availableExpirableCredits
  }, [availableCredits, availableExpirableCredits])

  const isLogin = useIsLogin()
  const pathname = usePathname()

  const refreshCredit = useCallback(async () => {
    if (!isLogin || pathname.startsWith('/auth')) {
      return
    }
    const credit = await getCreditBalance()
    if (credit) {
      setAvailableCredits(credit.available_credits || 0)
      setExpirableCredits(credit.expirable_credits || 0)
      setPermentCredits(credit.permanent_credits || 0)
      setPendingSettlementCredits(credit.pending_settlement_credits || 0)
    }
  }, [isLogin, pathname])

  useEffect(() => {
    if (isLogin) {
      refreshCredit()
    }
  }, [isLogin, refreshCredit])

  return {
    creditBalance: availableCredits,
    availableCredits,
    expirableCredits,
    permentCredits,
    availablePermentCredits,
    availableExpirableCredits,
    productsList: pricingTable?.credit_topup_products || [],
    spendProductsList: pricingTable?.credit_spend_products || [],
    refreshCredit,
  }
}

const useCredit = () => {
  const data = useContext(CreditContext)

  const varifyCredit = useCallback(
    (spend: number | 'Free') => {
      if (spend === 'Free') {
        return true
      }
      return (data.creditBalance || 0) >= spend
    },
    [data],
  )

  const getCreditPrice = useCallback(
    ({ mode, duration, resolution, version, template_id }: GetCreditPriceParams) => {
      if (template_id) {
        return TEMPLATE_CREDIT_PRICE
      }

      const spuName = template_id ? 'template/any2vid' : CREATE_MODE_ALIAS[version][mode as CreationModeEnum] || mode

      const generationSpu = data.spendProductsList.find((item: CreditSpendProduct) => {
        return item.spu === spuName
      })

      if (generationSpu) {
        let currentSku = null
        if (mode === 'CreateImg' || mode === 'generation/txt2img' || mode === 'gen2/txt2img') {
          // return 2
          currentSku = generationSpu.skus.find((item) => item.number === 4 && item.resolution === resolution)
        } else {
          currentSku = generationSpu.skus.find((item) => item.second === duration)
        }
        if (!currentSku || currentSku.amount === 0) {
          return 'Free'
        }
        return currentSku.amount
      }
      return 'Free'
    },
    [data],
  )

  return { ...data, varifyCredit, getCreditPrice }
}

export default useCredit
